
export default defineNuxtRouteMiddleware(to => {

    const route = useRoute();

    const centralStore = useCentralStore();

    if (process.client && route.query._token){
        centralStore.login(route.query._token)
        return navigateTo('/');
    }

    if(typeof route.query._token === "undefined")
    {

    }
})